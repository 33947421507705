import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import steven from './steven.jpeg'

export const AutoHeightContainer = styled.div`
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: relative;
`

export const ResumeContainer = styled(Row)`
  height: 100%;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  color: #fff;
  position: relative;
  background: #000;
  ::after {
    content: '';
    background-image: url(${steven});
    background-position: 50% 30%;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.8;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }
`

export const ResumeContentOverlayLeftContainer = styled(Col)`
  position: relative;
  padding: 0 3em 3em 3em;
  margin-top: 8em;
  margin-left: 2em;
  z-index: 100;
  h1 {
    font-size: 2em;
    margin-bottom: 0em;
  }
  h4 {
    font-size: 0.8em;
    margin-top: 0.1em;
    margin-bottom: 0em;
  }
  p.history {
    font-size: 1.6em;
  }
  p.contact {
    font-size: 1.3em;
  }

  ::after {
    z-index: -1;
    content: '';
    background: #000;
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }
`

export const ResumeContentOverlayFullContainer = styled(Col)`
  position: relative;
  text-align: center;
  padding: 5em 2em 3em 2em;
  z-index: 100;
  h1 {
    font-size: 1.2em;
    margin-bottom: 0em;
  }
  h4 {
    font-size: 0.6em;
    margin-top: 0.2em;
    margin-bottom: 0em;
  }
  p.history {
    font-size: 1.2em;
  }
  p.contact {
    font-size: 1.1em;
  }
  ::after {
    z-index: -1;
    content: '';
    background: #000;
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
  }
`

export const ResumeHeadingContainer = styled.div`
  padding-top: 0em;
  padding-bottom: 0.6em;
  font-size: 2em;
  opacity: 1;
`

export const ContactContentContainer = styled.div`
  position: relative;
  padding: 0 2em 2em 2em;
  background: #e1e1e1;
`

export const ContactHeadingContainer = styled.div`
  color: #333;
  background: #e1e1e1;
  padding: 1em 1em 0.6em 1em;
  font-size: 2em;
  text-align: center;
`

export const ContactParagraph = styled.p`
  font-style: italic;
`

export const ContactFormTextLink = styled.span`
  cursor: pointer;
  color: #e1e1e1;
  :hover {
    color: white;
  }
`
