import React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'

const MenuComponent: React.FunctionComponent<{
  className?: string
  onContactClick: () => void
}> = ({ className, onContactClick }) => (
  <Row end="xs" className={className}>
    <MenuLink
      onClick={e => {
        e.preventDefault()
        onContactClick()
      }}
    >
      <MenuItem>
        <ContactText>Contact</ContactText>
      </MenuItem>
    </MenuLink>
  </Row>
)

export const Menu = styled(MenuComponent)`
  height: 100%;
  text-align: center;
`

const MenuLink = styled.a`
  color: #000;
  cursor: pointer;
  &:hover {
    color: #666;
  }
  font-size: 1em;
  text-transform: uppercase;
`

const MenuItem = styled(Col)`
  font-size: 1em;
  text-transform: uppercase;
`

const ContactText = styled.div`
  padding: 2em;
  font-weight: 700;
`
