import { useState } from 'react'
import * as React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'

import { useRef } from 'react'

import {
  AutoHeightContainer,
  ContactContentContainer,
  ContactFormTextLink,
  ContactHeadingContainer,
  ContactParagraph,
  ResumeContainer,
  ResumeContentOverlayFullContainer,
  ResumeContentOverlayLeftContainer,
  ResumeHeadingContainer,
} from './steven-page.styles'
import { scrollIntoView } from 'utils/handlers'
import { Header } from 'components/header/header.component'
import { ContactForm } from 'components/contact/contact.component'
import { services } from 'services'
import { delayPromise } from 'utils/promise'
import { Layout } from 'components/layout/layout.component'

export interface PeertjeLabsStevenPageProps {}

export const PeertjeLabsStevenPage: React.FunctionComponent<PeertjeLabsStevenPageProps> = () => {
  const messageFieldRef = useRef(null)
  const [sendContactSuccess, setSendContactSuccess] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const handleContactClick = (e?: any) => {
    if (e) {
      e.preventDefault()
    }
    scrollIntoView(messageFieldRef.current, window, () =>
      (messageFieldRef.current as any).focus(),
    )
  }
  return (
    <Layout>
      <Row style={{ height: '100%', padding: 0, margin: 0 }}>
        <Col xs={12} style={{ height: '100%', padding: 0, margin: 0 }}>
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              opacity: 0.9,
              zIndex: 101,
            }}
          >
            <Header onContactClick={handleContactClick} />
          </div>
          <ResumeContainer>
            <ResumeContentOverlayLeftContainer xs={false} md={4}>
              {resumeContent(handleContactClick)}
            </ResumeContentOverlayLeftContainer>
            <ResumeContentOverlayFullContainer xs={12} md={false}>
              <Row middle="xs" style={{ height: '100%' }}>
                <Col xs={12} sm={10} smOffset={1}>
                  {resumeContent(handleContactClick)}
                </Col>
              </Row>
            </ResumeContentOverlayFullContainer>
          </ResumeContainer>
          <AutoHeightContainer>
            <ContactHeadingContainer>
              <h2>Contact Steven</h2>
            </ContactHeadingContainer>
            <ContactContentContainer>
              <Row>
                <Col xs={12} md={6} mdOffset={3} lg={4} lgOffset={4}>
                  <ContactForm
                    loading={isLoading}
                    backupEmail={'steven@peertjelabs.nl'}
                    messageFieldRef={messageFieldRef}
                    success={sendContactSuccess}
                    errorMessage={errorMessage}
                    handleSendMessage={data => {
                      setIsLoading(true)
                      return services.peertjelabs
                        .submitContactForm(data)
                        .then(result => {
                          setSendContactSuccess(true)
                          setIsLoading(false)
                          return delayPromise(5000, result).then(() => {
                            setSendContactSuccess(false)
                            return result
                          })
                        })
                        .catch(() => setIsLoading(false))
                    }}
                  />
                </Col>
              </Row>
            </ContactContentContainer>
          </AutoHeightContainer>
        </Col>
      </Row>
    </Layout>
  )
}

const resumeContent = (handleContactClick: (e?: any) => void) => (
  <React.Fragment>
    <ResumeHeadingContainer>
      <h1>Steven Kampen</h1>
      <h4>Director, Peertje Labs B.V.</h4>
    </ResumeHeadingContainer>
    <p className="history">
      Steven has worked in the software industry for over ten years as a
      engineer and product development consultant. He specializes in helping
      fledgling software projects and teams get to the next level using the
      Agile methodology.
    </p>
    <p className="history">
      He started Peertje Labs in 2019 to pursue a collection of interconnected
      endeavors.
    </p>
    <ContactParagraph className="contact">
      If you have a software project that Steven might be able to help with, use
      the{' '}
      <ContactFormTextLink onClick={(e: any) => handleContactClick(e)}>
        <u>contact form</u>
      </ContactFormTextLink>{' '}
      to send him a message directly.
    </ContactParagraph>
  </React.Fragment>
)
