import React from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { Menu } from '../menu'

export const Header: React.FunctionComponent<{
  className?: string
  onContactClick: () => void
}> = ({ onContactClick }) => (
  <>
    <Row end="xs" style={{ background: '#FFF' }}>
      <Col xs={9}>
        <PeertjeLabsTitle>
          <PeertjeLabsTextAnchor href="/">Peertje Labs</PeertjeLabsTextAnchor>
        </PeertjeLabsTitle>
      </Col>
      <Col xs={3}>
        <Menu onContactClick={onContactClick} />
      </Col>
    </Row>
  </>
)

const PeertjeLabsTitle = styled.h1`
  padding: 0 1em;
`

const PeertjeLabsTextAnchor = styled.a`
  text-decoration: none;
  color: #333;
  :hover {
    color: #666;
  }
`
